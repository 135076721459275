import cn from 'classnames';
import { useState } from 'react';
import { Wrapper } from 'src/components/Wrapper';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { AdditionalLinks } from 'src/layout/Header/components/AdditionalLinks';
import { ADDITIONAL_LINKS } from 'src/layout/Header/components/Products';
import { Catalog } from 'src/types/backendContent';
import { ArrowOut } from 'src/uikit/icons/ArrowOut';

import { Link } from '../Link';
import { Tabs } from '../Tabs';
import s from './CatalogBlock.module.scss';

interface CardProps {
  icon: string;
  title: string;
  markTitle?: string;
  slug: string;
  customLink: string | null;
  isPlatform: boolean;
  desciption?: string;
  platformName?: string;
  withOutTags?: boolean;
}

function Card({
  icon,
  title,
  markTitle,
  slug,
  customLink,
  isPlatform,
  desciption,
  platformName,
  withOutTags = false,
}: CardProps) {
  const currentLink = customLink ? customLink : `/products/${slug}`;
  const currentIcon = icon ? icon : '/plugs/product-2d-icon-default.svg';

  return (
    <Link
      href={currentLink}
      className={cn(s.card, { [s.cardPlatform]: isPlatform })}
      analyticsOptions={{
        action: currentLink,
        clickZone: 'body',
        clickElement: 'cardclick',
        clickContent: title,
        uniqueId: `catalog-product-${title}`,
        transitionType: 'inside-link',
      }}
    >
      <img src={currentIcon} alt={title} className={s.cardIcon} />
      <div className={s.cardContent}>
        {markTitle && (
          <h2
            className={s.cardTitle}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        <h2
          className={cn(s.cardMarkTitle, {
            [s.cardMarkTitleSmall]: !markTitle,
          })}
          dangerouslySetInnerHTML={{
            __html: markTitle ? markTitle : title,
          }}
        />
        {isPlatform && desciption && <p className={s.cardText}>{desciption}</p>}
        {!isPlatform && platformName && !withOutTags && (
          <div className={s.cardTag}>{platformName}</div>
        )}
      </div>
      <ArrowOut className={s.arrow} />
    </Link>
  );
}

interface CatalogBlockProps {
  id?: string;
  title: string;
  categories: Catalog['categories'];
  products: Catalog['products'];
  withOutTags?: boolean;
}

export function CatalogBlock({
  id,
  title,
  categories,
  products,
  withOutTags = false,
}: CatalogBlockProps) {
  const [activeCategory, setActiveCategory] = useState(categories[0].id);
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <section className={s.root} id={id}>
      <Wrapper className={s.wrapper}>
        <h2 className={s.title}>{title}</h2>
      </Wrapper>
      {windowWidth && windowWidth <= MD && (
        <Tabs
          classNameContainer={s.tabsWrapper}
          className={s.tabs}
          tabs={categories}
          variant="horizontal"
          onClick={(tab) => setActiveCategory(tab.id)}
          activeTab={activeCategory}
        />
      )}
      <Wrapper className={s.wrapper}>
        <div className={s.content}>
          {windowWidth && windowWidth > MD && (
            <div className={s.contentInner}>
              <Tabs
                className={s.tabs}
                tabs={categories}
                variant="vertical"
                onClick={(tab) => setActiveCategory(tab.id)}
                activeTab={activeCategory}
              />
              <AdditionalLinks
                links={ADDITIONAL_LINKS}
                className={s.catalogBlockLinks}
              />
            </div>
          )}
          <div className={s.products}>
            {categories.map(({ id }) => {
              const categoryProducts = products.filter((product) =>
                product.categories?.find((category) => category.id === id),
              );

              return (
                <div
                  key={id}
                  className={cn(s.block, {
                    [s.active]: id === activeCategory,
                    [s.platforms]: id === 'platforms',
                  })}
                >
                  {categoryProducts.map(
                    ({
                      id: cardId,
                      description,
                      title,
                      markTitle,
                      icon,
                      customLink,
                      slug,
                      productPlatforms,
                    }) => {
                      const platformName =
                        productPlatforms.length > 1
                          ? 'Кроссплатформенный '
                          : productPlatforms[0].title;

                      return (
                        <Card
                          isPlatform={id === 'platforms'}
                          key={cardId}
                          title={title}
                          desciption={description}
                          markTitle={markTitle}
                          icon={icon}
                          customLink={customLink || ''}
                          slug={slug}
                          platformName={platformName}
                          withOutTags={withOutTags}
                        />
                      );
                    },
                  )}
                </div>
              );
            })}
          </div>

          {windowWidth && windowWidth < MD && (
            <AdditionalLinks
              links={ADDITIONAL_LINKS}
              className={s.catalogBlockLinks}
            />
          )}
        </div>
      </Wrapper>
      <img
        className={s.bg}
        src="https://cdn.cloud.ru/backend/images/pages/index/catalog_block_bg.svg"
        alt=""
      />
    </section>
  );
}
