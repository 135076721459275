export const KRAKEN_COUNTER_SCRIPT = `
    (function (w, d, c) { (w[c] = w[c] || []).push(function() {var options = {project: 7731830,};try {w.top100Counter = new top100(options);} catch(e) { }});var n = d.getElementsByTagName("script")[0],s = d.createElement("script"),f = function () { n.parentNode.insertBefore(s, n); };s.type = "text/javascript";s.async = true;s.src=(d.location.protocol == "https:" ? "https:" : "http:") +"//st.top100.ru/top100/top100.js";    if (w.opera == "[object Opera]") {d.addEventListener("DOMContentLoaded", f, false);} else { f(); }})(window, document, "_top100q");
`;

export const getYMScript = (ymid: string) =>
  `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();
        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${ymid}, "init", { 
          defer:true,
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true,
          ecommerce:"dataLayer" 
          }
    );
`;

export const getYMVarioqubScript = (ymid: string) =>
  `(function(e, x, pe, r, i, me, nt){ e[i]=e[i]||function(){(e[i].a=e[i].a||[]).push(arguments)},
      me=x.createElement(pe),me.async=1,me.src=r,nt=x.getElementsByTagName(pe)[0],nt.parentNode.insertBefore(me,nt)}) 
      (window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab'); 
      ymab('metrika.${ymid}', 'setConfig', { enableWatch: true });
      ymab('metrika.${ymid}', 'init'/*, {clientFeatures}, {callback}*/);`;

export const VK_COUNTER =
  'var _tmr = window._tmr || (window._tmr = []); _tmr.push({id: "3471163", type: "pageView", start: (new Date()).getTime()}); (function (d, w, id) { if (d.getElementById(id)) return; var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id; ts.src = "https://top-fwz1.mail.ru/js/code.js"; var f = function (){var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);}; if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); } })(document, window, "tmr-code");';

export const JIVO_SCRIPT = '//code.jivo.ru/widget/LtwPGEyUzf';

export const organizationMicroData = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  name: 'Cloud.ru',
  description:
    'Cloud.ru (ООО «Облачные технологии») – Облачная платформа IaaS/PaaS и Machine Learning сервисов. Облачные услуги, вычислительные ресурсы, хранение данных в облаке, мониторинг и управление, сервисы для Big Data.',
  url: 'https://cloud.ru/',
  logo: 'https://cloud.ru/favicons/logo-180.png',
  email: 'support@cloud.ru',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'RU',
    postalCode: '123100',
    addressLocality: 'Moscow, Russia',
    streetAddress: 'ул. 2-я Звенигородская, 28',
  },
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+7-495-260-1082',
      contactType: 'Обслуживание клиентов',
      areaServed: 'RU',
      availableLanguage: 'Russian',
      email: 'support@cloud.ru',
      url: 'https://cloud.ru',
    },
  ],
  sameAs: [
    'https://www.youtube.com/@cloudru_team',
    'https://t.me/cloudruprovider',
    'https://vk.com/cloudruprovider',
  ],
};
